import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Observable, of, retry, Subject } from 'rxjs';
import { map, mergeMap, switchMap, takeUntil } from 'rxjs/operators';
import { API_URL_GATEWAY } from 'src/app/api-service.config';
import { PaymentData } from 'src/app/common/json/payment-data.json-interface';
import { PayPalResponse } from 'src/app/common/json/paypal-response.json-interface';
import { PaymentFormData } from 'src/app/common/models/payment-form-data';
import { PaymentLink } from 'src/app/common/models/payment-link';
import { Product } from 'src/app/common/models/product';
import { StorageService } from 'src/app/common/services/storage.service';
import { AddCardLink, AddPaypalLink } from 'src/app/store/actions/payment-link.actions';
import { SetOrderIdCard, SetOrderIdPaypal } from 'src/app/store/actions/subscription.actions';
import { ChangeSubscriptionSwitch } from 'src/app/store/actions/user-info.actions';
import { selectUserInfo } from 'src/app/store/selectors/user-info.selector';
import { IAppState } from 'src/app/store/states/app.state';
import { IUserInfoState } from 'src/app/store/states/user-info.state';
import { v4 as uuidv4 } from 'uuid';
// import { GlobalErrorHandler } from './error-log.service';

@Injectable()
export class SolidService {

  private userInfoObservable = this.store.pipe(select(selectUserInfo));

  private userInfo!: IUserInfoState;

  private destroy = new Subject<void>();

  private cardSubject = new Subject<Product>();

  private paypalSubject = new Subject<Product>();

  constructor(
    private readonly httpClient: HttpClient,
    @Inject(API_URL_GATEWAY) private readonly api: string,
    private readonly store: Store<IAppState>,
    private readonly storageService: StorageService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly translate: TranslateService,
    // private readonly globalErrorHandler: GlobalErrorHandler,
  ) {
    this.subscriptionStore();
  }

  public switchSubscription(subscriptionID: string, newId: string, method: string, restoreCanceled = false): Observable<string> {
    const body = {
      subscription_id: subscriptionID,
      new_product_id: newId,
      restore_cancelled: restoreCanceled,
      payment_type: method,
    };
    return this.httpClient
      .post<any>(`${ this.api }/subscriptions/switch`, body)
      .pipe(map(() => {
        this.store.dispatch(new ChangeSubscriptionSwitch(true));
        return 'OK';
      }));
  }

  public refundSwitchSubscription(subscriptionID: string, newId: string, orderId: string, method: string, skipRefund = false): Observable<string> {
    let params = new HttpParams();
    skipRefund ? params = new HttpParams().set('skipRefund', 'true') : '';
    const body = {
      subscription_id: subscriptionID,
      new_product_id: newId,
      trial_order_id: orderId,
      payment_type: 'card',
    };
    return this.httpClient
      .post<any>(`${ this.api }/subscriptions/refund_trial_switch`, body, { params })
      .pipe(map(() => {
        this.store.dispatch(new ChangeSubscriptionSwitch(true));
        return 'OK';
      }));
  }

  public updatePaymentForm(product: Product): void {
    this.cardSubject.next(product);
  }

  public getPaymentForm(): Observable<PaymentFormData> {
    return this.cardSubject
      .pipe(switchMap(product => {
        return this.userInfo.purchases && this.userInfo.purchases.length ? this.getPaymentPurchase(product) : this.getPaymentSubscription(product);
      }));
  }

  public updatePaypalLink(product: Product): void {
    this.paypalSubject.next(product);
  }

  public getPaypalLink(): Observable<string> {
    return this.paypalSubject
      .pipe(switchMap(product => {
        return this.userInfo.purchases && this.userInfo.purchases.length ? this.getPaypalPurchase(product) : this.getPaypalSubscription(product);
      }));
  }

  public buyLifeTimeProducts(products: Product[], cardTokenOrderId: string, oneClickPayment = false): Observable<{ products: Product[], orderIds: string[] }> {
    const productsIds = products.map(item => item.id);
    const body: any = {
      products_ids: productsIds,
      card_token_order_id:cardTokenOrderId,
      order_description: products[0].publicDescription,
      purchase_site: 'PaymentSite',
    };

    if (oneClickPayment) {
      body.one_click_payment = true;
    }

    return this.httpClient
      .post<{order: {status: string, order_id: string}}>(`${this.api}/subscriptions/recurring_payment`, body)
      .pipe(
        mergeMap(response => {
          if (!response) {
            return of({ products: [], orderIds: [] });
          }
          if (response.order.status !== 'declined' && response.order.status !== 'auth_failed') {
            return of({ products, orderIds: [response.order.order_id] });
          }

          if ((response.order.status === 'declined' || response.order.status === 'auth_failed') && products.length === 1) {
            return of({ products: [], orderIds: [] });
          }
          const observables = products.map(product => this.buyLifeTimeProduct(product, cardTokenOrderId));
          return forkJoin(observables)
            .pipe(map(answer => {
              const products: Product[] = [];
              const orderIds: string[] = [];
              answer.forEach(item => {
                if (item.status !== 'declined' && item.status !== 'auth_failed') {
                  products.push(item.product);
                  orderIds.push(item.order_id);
                }
              });
              return { products, orderIds };
            }));
        }),
        );
  }

  public buyLifeTimeProduct(product: Product, cardTokenOrderId: string): Observable<{status: string, product: Product, order_id: string }> {
    const productsIds = [product].map(item => item.id);

    return this.httpClient
      .post<{order: {status: string, order_id: string}}>(`${this.api}/subscriptions/recurring_payment`, {
        products_ids: productsIds,
        card_token_order_id:cardTokenOrderId,
        order_description: product.publicDescription,
        purchase_site: 'PaymentSite',
      })
      .pipe(
        retry(3),
        map(response => {
          return {
            product,
            status: response.order.status,
            order_id: response.order.order_id,
          };
        }),
        );
  }

  private getPaymentSubscription(product: Product): Observable<PaymentFormData> {

    const orderId = uuidv4();

    const body = {
      ...this.getBodyForOrderCreate(product),
      language_code: this.translate.currentLang,
      order_id: orderId,
      apple_pay_domain: window.location.hostname,
    };

    return this.httpClient
      .post<{ payload: PaymentData }>(`${ this.api }/subscriptions/create_payment_intent`, body)
      .pipe(
        retry(3),
        map(answer  => {
          const response = answer.payload;
          this.store.dispatch(new AddCardLink(new PaymentLink(product.id, response.Signature)));
          this.store.dispatch(new SetOrderIdCard(orderId));
          return new PaymentFormData(response.Merchant, response.PaymentIntent, response.Signature);
        }));
  }

  private getPaymentPurchase(product: Product): Observable<PaymentFormData> {
    const orderId = uuidv4();
    const body = {
      ...this.getBodyForOrderCreate(product),
      order_id: orderId,
      apple_pay_domain: window.location.hostname,
    };

    return this.httpClient
      .post<{ payload: PaymentData }>(`${ this.api }/subscriptions/create_purchase_payment_intent`, body)
      .pipe(
        retry(3),
        map(answer => {
          const response = answer.payload;
          this.store.dispatch(new AddCardLink(new PaymentLink(product.id, response.Signature)));
          this.store.dispatch(new SetOrderIdCard(orderId));
          return new PaymentFormData(response.Merchant, response.PaymentIntent, response.Signature);
        }));
  }

  private getPaypalSubscription(product: Product): Observable<string> {

    const orderId = uuidv4();

    const body = {
      ...this.getBodyForOrderCreate(product),
      order_id: orderId,
      language_code: this.translate.currentLang,
    };

    return this.httpClient
      .post<{ payload: PayPalResponse }>(`${ this.api }/subscriptions/create_pp_subscription`, body)
      .pipe(
        // retry(3),
        map(answer => {
          const response = answer.payload;
          this.store.dispatch(new SetOrderIdPaypal(orderId));
          this.store.dispatch(new AddPaypalLink(new PaymentLink(product.id, response.script_url)));
          const { script_url } = response;
          return script_url;
        }));
  }

  private getPaypalPurchase(product: Product): Observable<string> {

    const orderId = uuidv4();

    const body = {
      ...this.getBodyForOrderCreate(product),
      order_id: orderId,
    };

    return this.httpClient
      .post<{ payload: PayPalResponse }>(`${ this.api }/subscriptions/create_pp_purchase`, body)
      .pipe(
        retry(3),
        map(answer => {
          const response = answer.payload;
          this.store.dispatch(new SetOrderIdPaypal(orderId));
          this.store.dispatch(new AddPaypalLink(new PaymentLink(product.id, response.script_url)));
          const { script_url } = response;
          return script_url;
        }));
  }

  private subscriptionStore(): void {
    this.userInfoObservable
      .pipe(takeUntil(this.destroy))
      .subscribe(data => this.userInfo = data!);
  }

  private getBodyForOrderCreate(product: Product): {} {
    const params = this.activatedRoute.snapshot.queryParamMap;
    const traficSource = params.get('campaign_id');
    const utmSource = params.get('utm_source');

    let fbc = this.getFromCookie('_fbc');
    const urlParams = new URLSearchParams(window.location.search);
    const fbclid = urlParams.get('fbclid');
    if (!fbc && fbclid) {
      fbc = `fb.1.${ new Date().getTime().toString() }.${ fbclid }`;
    }

    const result: any = {
      fbc,
      traffic_source: traficSource,
      website: utmSource,
      source_url: window.location.href,
      purchase_site: 'PaymentSite',
      order_description: product.publicDescription,
      event_id: this.storageService.getUserId(),
      fbp: this.getFromCookie('_fbp'),
      user_agent: navigator.userAgent,
      ttp: this.getFromCookie('_ttp') || '',
      ttclid: this.activatedRoute.snapshot.queryParamMap.get('ttclid') || '',
      page_referer: window.location.origin,
    };

    if (this.userInfo.purchases && this.userInfo.purchases.length) {
      result.products_ids = this.userInfo.purchases.map(item => item.id);
    } else {
      result.product_id = product.id;
    }

    return result;
  }

  private getFromCookie(name: string): string | null {
    const matches = document.cookie.match(new RegExp(
      `(?:^|; )${name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1')}=([^;]*)`,
    ));
    return matches ? decodeURIComponent(matches[1]) : null;
  }
}
