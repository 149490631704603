import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GeoService {
  private readonly LATAM_LIST = new Set([
    'ARG', 'BOL', 'BRA', 'CHL', 'COL', 'CRI', 'CUB', 'DOM', 'ECU', 'SLV', 'GTM', 'GUY', 'HTI', 'HND', 'JAM', 'MEX', 'NIC', 'PAN', 'PRY', 'PER', 'SUR', 'URY', 'VEN'
  ])
  private geo = new BehaviorSubject('USA');

  private latam = new BehaviorSubject(false);

  public getGeo(): Observable<string> {
    return this.geo.asObservable();
  }

  public setGeo(geo: string): void {
    this.geo.next(geo);
    this.setLatam(geo);
  }

  public getLatam(): Observable<boolean> {
    return this.latam.asObservable()
  }

  private setLatam(geo: string): void {
    this.latam.next(this.isLatam(geo));
  }

  private isLatam(geo: string): boolean {
    return this.LATAM_LIST.has(geo);
  }
}
